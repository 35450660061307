import { GridWrapper } from './style';
import Link from 'next/link';

export const Grid = () => {
  return (
    <GridWrapper>
      <Link href="/services/archeology">Archeology</Link>
      <Link href="/services/architectural-history">Architectural History</Link>
      <Link href="/services/history">History</Link>
    </GridWrapper>
  );
};
